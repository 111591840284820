import {getCookie, setCookie} from './cookies';

let initHeaderHandlers = function() {
    $('.js-wcag-switcher').click(function(e){
        e.preventDefault();

        const cookie_expire = 60 * 60 * 24 * 360 * 5;
        let config = {
            expires: cookie_expire,
            path: '/'
        };

        let wcag = getCookie('wcag');
        if(typeof wcag == 'undefined'){
            wcag = false;
        }

        setCookie('wcag', Number(wcag) ? '0' : '1', config);

        document.location.reload(true);
    });

    $('.js-main-nav-link').on('click', function(e) {
        e.preventDefault();

        let parentLi = $(this).closest('.js-main-nav-li');

        if ( !parentLi.hasClass('active') ) {

            $('.js-main-nav-li').removeClass('active');
            $('.js-main-nav-search').removeClass('active');
            parentLi.addClass('active');
            $('.js-main-nav-sub-menu').slideUp(200);
            $('.js-header-search').slideUp(200);
            parentLi.find('.js-main-nav-sub-menu').slideDown(200);

        } else {

            parentLi.removeClass('active');
            parentLi.find('.js-main-nav-sub-menu').slideUp(200);

        }

    });

    $('.js-open-header-search').on('click', function(e) {
        e.preventDefault();
        $('.js-main-nav-sub-menu').slideUp(200);
        $('.js-main-nav-li').removeClass('active');
        $('.js-header-search').slideToggle(200);
        $('.js-main-nav-search').toggleClass('active');
    });

    $('.js-open-header-search-mob').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();
        $('.js-main-nav').slideUp(200);
        $('.js-open-mob-menu').removeClass('active');
        $('.js-header-search').slideToggle(200);
        $('.js-main-nav-search').toggleClass('active');
    });

    $('.js-close-header-search').on('click', function(e) {
        e.preventDefault();
        $('.js-header-search').slideToggle(200);
        $('.js-main-nav-search').toggleClass('active');
    });

    $('.js-form-switcher').click(function (e) {
        e.preventDefault();

        $(this).parents('form').attr('action', $(this).data('action'));

        $(this).parents('form').submit();
    });

    // Закрываем открытие блоки в хедере, если клик произошел не по ним и не по кнопкам(которые их открывают)
    $(document).on('click', function (e) {

        let targetMainMenu = $(e.target).closest(".js-main-nav"),
            targetHeaderSearch = $(e.target).closest(".js-header-search");

        if ( !targetMainMenu.length && !targetHeaderSearch.length ) {
            $('.js-header-search').slideUp(200);
            $('.js-main-nav-sub-menu').slideUp(200);
            $('.js-main-nav-li').removeClass('active');
            $('.js-main-nav-search').removeClass('active');
        }

    });

    $('.js-open-mob-menu').on('click', function(e) {
        e.preventDefault();

        $('.js-twitter-widget').toggleClass('visible-index');

        $(this).toggleClass('active');
        $('.js-main-nav').slideToggle(200);

    });

    // Side Menu
    $('.js-open-child-menu').on('click', function(e) {
        e.preventDefault();

        let parentLi = $(this).parent('.js-parent-li'),
            childMenu = parentLi.children('.js-child-menu');

        childMenu.slideToggle(200);
        parentLi.toggleClass('open');

    });

    var main_content=0    
    $(document).keydown(function(e) {

        if (main_content === 0 && e.keyCode == 9 && $('#main_content_modal').css('display') == 'none') {
        e.preventDefault();
                console.log('tab pressed');
		$('#main_content_modal').css('display','block');
        	$('#main_content_link').focus();
    		main_content += 1
        }
        else {
        $('#main_content_modal').css('display','none');
        }
            	});
};
    $(document).ready(function() {
    $("#main_content_link").click(function(event){
            $('html, body').animate({scrollTop: '+=420px'}, 800);
    });
    });
export default initHeaderHandlers;