let infiniteContent = function(containerEl, url) {

    let _this = containerEl,
        inProgress = false,
        finish = false;

    if ( _this.length === 0 ) {
        return false;
    }

    let contentLoad = function() {

        let windowTrigger = $(window).scrollTop() + $(window).height(), // высота прокрутки + высота окна
            containerTrigger = _this.offset().top + _this.outerHeight(), // высота контейнера + верхняя координата контейнера
            offsetPoint = -400; // смещение точки срабатывания запроса.
                                // Положительное значение - поднимает точку срабативания вверх.
                                // Отрицательное значение - опускает точку срабатывания вниз.

        // если (высота прокрутки + высота окна) больше или равна (высота контейнера + верхняя координата контейнера)
        // и не выполняеться запрос (!inProgress)

        
        if ( windowTrigger >= (containerTrigger + offsetPoint) && !inProgress && !finish ) {

            // предотвращаем повторное выполнение запроса при срабатывании скролла
            inProgress = true;

            // собираем id загруженных на страницу item-мов
            let arrId = [];
            _this.parents('.infinite-wrapper').find('[data-id]').each(function(index) {
                arrId.push($(this).attr("data-id"));
            });

            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });

            $.ajax({
                url,
                method: 'post',
                data: {ids:arrId},
                beforeSend: function( xhr ) {
                    _this.append('<div class="loader-content"><img src="/assets/img/loader.gif" alt=""></div>');
                    inProgress = true;
                },
                error: function(data){
                    finish = true;
                    _this.find('.loader-content').remove();
                },
                success: function (data) {
                    _this.find('.loader-content').remove();
                    inProgress = false;

                    if(data == ''){
                        finish = true;
                    }else {
                        containerEl.append(data);

                        contentLoad();
                    }
                }
            });

        }

    };
    contentLoad();
    $(window).scroll(function(){
        contentLoad();
    });

};

let getMore = function(){
    $('.get-more').click(function(e){
        e.preventDefault();

        if($(this).parents('.infinite-wrapper').length) {
            infiniteContent($(this).parents('.infinite-wrapper').find('.infinite-container'), $(this).attr('href'));
        }

        $(this).hide();
    });
};

$(document).ready(function() {
    if($('.get-more').length) {
        getMore();
    }
});


