let initMoreInfo = function(el) {

    let moreWrap = el,
        moreContent = moreWrap.find('.js-more-content'),
        switchOpen = false;

    moreWrap.find('.js-more-btn').on('click', function(e) {
        e.preventDefault();

        moreContent.slideToggle(200, function() {
            moreWrap.toggleClass('open');
        });

    });

    moreWrap.find('.js-more-title').on('click', function(e) {
        e.preventDefault();

        moreContent.slideToggle(200, function() {
            moreWrap.toggleClass('open');
        });

    })

};

export default initMoreInfo;