import "malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min";
import "malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css";

let initCustomScrollBar = function() {

    $('.js-custom-scroller').mCustomScrollbar({
        axis: "y",
        theme: "select-chosen",
        scrollInertia: 1500,
    });

};

let initCustomScrollBarChosen = function(scrollerBlock) {

    $(scrollerBlock).mCustomScrollbar({
        axis: "y",
        theme: "select-chosen"
    });

};

let destroyCustomScrollBar = function(scrollerBlock) {

    $(scrollerBlock).mCustomScrollbar("destroy");

};

export {initCustomScrollBar, initCustomScrollBarChosen, destroyCustomScrollBar};