
let footerHandlers = function() {

    // Show/Hide map menu
    $('.js-open-footer-nav').on('click', function() {

        $(this).toggleClass('active');
        $('.js-footer-sub-nav').slideToggle(200);

    });

};

export default footerHandlers;