
// Accordion

let _accordion = function(accordionEl, userSettings) {

	let _this = accordionEl;
	let settings = {
		speed: 400, // animation speed
		oneOpen: true, // close all other accordion items if true
		firstOpen: false, // open/close first accordion item
	};

	let methods = {

		init: function() {

			let blocks = this.getElements();

			this.addClass();

			$.extend(settings, userSettings);

			if ( settings.firstOpen && blocks.accordionItem.length > 1 ) {
				$(blocks.accordionItem)
					.eq(0)
					.addClass('open')
					.children('.accordion__body')
					.slideDown();
			}

			blocks.accordionHeader.on('click', function() {

				methods.toggle($(this));

			});

		},

		toggle: function($this) {

			if ( settings.oneOpen && !$this.closest('.accordion__item').hasClass('open') ) {
				$this.closest('.accordion')
					.find('> .accordion__item')
					.removeClass('open')
					.find('> .accordion__body')
					.slideUp()
			}

			$this.closest('.accordion__item').toggleClass('open');
			$this.next('.accordion__body').slideToggle(settings.speed);

		},

		addClass: function() {
			let blocks = this.getElements();

			blocks.accordionItem.each(function(index, element) {

				if ( !$(element).hasClass('accordion__item') ) {
					$(element).addClass('accordion__item');
				}

			});

			blocks.accordionHeader.each(function(index, element) {

				if ( !$(element).hasClass('accordion__head') ) {
					$(element).addClass('accordion__head');
				}

			});

			blocks.accordionBody.each(function(index, element) {

				if ( !$(element).hasClass('accordion__body') ) {
					$(element).addClass('accordion__body');
				}

			});
		},

		getElements: function() {
			return {
				accordionItem: _this.children() || '',
				accordionHeader: _this.children().find('> *:first-child') || '',
				accordionBody: _this.children().find('> *:last-child') || ''
			};

		}

	};


	return methods.init();

};

$(document).ready(function(){
	_accordion($('.js-accordion'), {
		firstOpen: true,
	});
});