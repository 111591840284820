import footerHandlers from "./components/_footer";
import initHeaderHandlers from "./components/_header";
import initProgressBar from "./components/_progress-bar";
import datepickersInit from "./components/_datepickers";
import initGallerySlider from "./components/_gallery";
import initMoreInfo from "./components/_more-info";
import {initCustomScrollBar} from "./components/_scrollbar-custom";
import initTwitterWidget from "./components/_twitter-widget";
import initFontSizeSite from "./components/_font-size-controller";
import VatCheckFields from "./components/_vat-check";

$(document).ready(function() {

    initHeaderHandlers();
    footerHandlers();
    initProgressBar($('.progress'));
    datepickersInit();
    initCustomScrollBar();
    initTwitterWidget();
    initFontSizeSite();
    VatCheckFields();

    $('.js-gallery-slider').each(function() {
        initGallerySlider($(this));
    });

    $('.js-more-info').each(function() {
        initMoreInfo($(this));
    });


});