let initProgressBar = function(progresBar) {

// https://stackoverflow.com/questions/39051691/how-can-i-create-semicircle-progress-bar-with-jquery

    progresBar.each(function(){

        let $bar = $(this).find(".bar"),
            $val = $(this).find(".progress-perc"),
            percStr = $val.text(),
            percNum = parseFloat($val.text().replace(",","."), 10);

	if (percNum > 100) {
	   percNum = 100;
	}
        $({p:0}).animate({p:percNum}, {
            duration: 2000,
            easing: "swing",
            step: function(p) {
                $bar.css({
                    transform: "rotate("+ (45+(p*1.8)) +"deg)", // 100%=180° so: ° = % * 1.8
                    // 45 is to add the needed rotation to have the green borders at the bottom
                });
                $val.text(p|0);
            },
            done: function() {
                $val.text(percStr);
            }
        });
    });

};

export default initProgressBar;
