import initSelectChosen from './_select-chosen';
import handlers from  './_form-handlers';

let handleUtils = function() {

	$('.select-chosen').each(function () {
		initSelectChosen($(this));
	});

};

let ajaxSubmit = function(){
    let form = $('form.ajax-submit');
    form.on('success', function(e, data){
        form.find('.submit-result').removeClass('hidden');
        form.find('.hide-on-success').addClass('hidden');
        form.trigger("reset");
    });
    form.find('.ajax-submit-btn').click(function(e){
        e.preventDefault();

        handlers.ajaxSubmit(form);
        return false;
    });
};

$(document).ready(function(){
    handleUtils();
    ajaxSubmit();
});