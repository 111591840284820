import {getCookie, setCookie} from './cookies';

let initFontSizeSite = function() {

    let cookiesFontSize = getCookie('font-size-base');

    $(document).ready(function() {
        switch(cookiesFontSize) {
            case '18':
                $('.js-edit-font-size[data-fontSize="18"]').addClass('active-text');
                break;
            case '20':
                $('.js-edit-font-size[data-fontSize="20"]').addClass('active-text');
                break;
            default:
                $('.js-edit-font-size[data-fontSize="16"]').addClass('active-text');
                break;
        }
    });

    $('.js-font-size-controls').on('click', '.js-edit-font-size', function(e) {
        e.preventDefault();

        if ( !$(this).hasClass('active-text') ) {

            var fontSize = $(this).attr("data-fontSize");

            $('.js-edit-font-size').removeClass('active-text');
            $(this).addClass('active-text');

            const cookie_expire = 60 * 60 * 24 * 360 * 5;
            let config = {
                expires: cookie_expire,
                path: '/'
            };

            setCookie('font-size-base', fontSize, config);

            location.reload();

        }

        return false;

    });

};

export default initFontSizeSite;