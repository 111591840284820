import "jquery-ui/ui/widgets/datepicker";
import "jquery-ui/ui/i18n/datepicker-uk";

let datepickersInit = function() {

    $.datepicker.setDefaults( $.datepicker.regional['uk'] );

    let dateFormat = "dd-mm-yy",
        from = $( ".js-datepicker-from" )
            .datepicker({
                dateFormat: "yy-mm-dd",
                showOtherMonths: true,
                selectOtherMonths: true,
                beforeShow: function() {
                    let inputWidth = $(this).outerWidth();

                    if ( inputWidth >= 272 ) {
                        $(".ui-datepicker").css({ 'min-width': $(this).outerWidth() })
                    }
                }
            })
            .on( "change", function() {
                to.datepicker( "option", "minDate", getDate( this ) );
                $(this).trigger('focusout');
            }),
        to = $( ".js-datepicker-to" )
            .datepicker({
                dateFormat: "yy-mm-dd",
                showOtherMonths: true,
                selectOtherMonths: true,
                beforeShow: function() {
                    let inputWidth = $(this).outerWidth();

                    if ( inputWidth >= 272 ) {
                        $(".ui-datepicker").css({ 'min-width': $(this).outerWidth() })
                    }
                }
            })
            .on( "change", function() {
                from.datepicker( "option", "maxDate", getDate( this ) );
                $(this).trigger('focusout');
            });

    function getDate( element ) {
        let date;
        try {
            date = $.datepicker.parseDate( dateFormat, element.value );
        } catch( error ) {
            date = null;
        }

        return date;
    }

    $('.js-btn-init-datepicker').click(function () {
        $(this).siblings('input[type="text"]').trigger('focus');
    });

};

export default datepickersInit;