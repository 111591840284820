import "slick-carousel/slick/slick.min";
import "slick-carousel/slick/slick.css";

// General slick slider

let genSlickOptions = {   // general options
    dots: true,
    dotsClass: 'slick-main__dots',
    arrows: true,
    prevArrow: '<button type="button" class="slick-main__arrows slick-main__arrows_prew"><i class="icons icon-arrow-left"></i></button>',
    nextArrow: '<button type="button" class="slick-main__arrows slick-main__arrows_next"><i class="icons icon-arrow-right"></i></button>',
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    autoplay: false,
    autoplaySpeed: 3000,
};

let initSlickCarousel = function (element = $('.js-gen-slick-slider'), options = genSlickOptions) {

    let owlEl = element,
        owlOptions = options;

    let init = (newOwlEl, newOptions) => {
        newOwlEl = newOwlEl || owlEl;
        newOptions = Object.assign({}, owlOptions, newOptions);

        newOwlEl.slick(newOptions);
    };

    return {
        init: init
    }

}();

$(document).ready(function() {

    initSlickCarousel.init();

    initSlickCarousel.init($('.js-slider-news-post'), {
        adaptiveHeight: true,
        arrows: false
    });

    // Full Screen Slider
    $('.js-full-screen-slider').on('click', function(e) {
        e.preventDefault();

        let sliderWrap = $(this).closest('.js-slider-wrap'),
            slider = sliderWrap.find('.js-slick-main');

        sliderWrap.prepend("<div class='close-full-screen-slider js-close-full-screen-slider'><i class='icons icon-close-x'></i></div>");
        sliderWrap.addClass('big-slider');
        slider.slick( 'slickSetOption', {arrows: true}, true );
        // slider.slick('refresh');

    });

    // Close Full Screen Slider
    $('.js-slider-wrap').on('click', '.js-close-full-screen-slider', function() {

        let sliderWrap = $(this).closest('.js-slider-wrap'),
            slider = sliderWrap.find('.js-slick-main');

        $(this).remove();
        sliderWrap.removeClass('big-slider');
        slider.slick( 'slickSetOption', {arrows: false}, true );

    });

});

export default initSlickCarousel;