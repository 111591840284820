import initSlickCarousel from "./_slick-slider";

let initGallerySlider = function(element) {

    let parentWrap = element,
        sliderFor = parentWrap.find('.js-gallery-slider-for'),
        sliderNav = parentWrap.find('.js-gallery-slider-nav'),
        currentSlideNum = parentWrap.find('.js-current-slide'),
        slidesSum = parentWrap.find('.js-sum-slide'),
        downloadLink = parentWrap.find('.js-link-download'),
        imgSize = parentWrap.find('.js-current-img-size'),
        btnPrevSlide = parentWrap.find('.js-nav-prev'),
        btnNextSlide = parentWrap.find('.js-nav-next');

    sliderFor.on('init', function(event, slick) {
        let currentSlideEl = slick.$slides.eq(slick.currentSlide);

        slidesSum.text(slick.slideCount);
        downloadLink.attr("href", currentSlideEl.data("linkDownload"));
        imgSize.text(currentSlideEl.data("size"));
    });

    initSlickCarousel.init(sliderFor, {
        dots: false,
        infinite: false,
        speed: 600,
        prevArrow: '<button type="button" class="slick-main__arrows slick-main__arrows_prew"><i class="icons icon-arrow-circle-left"></i></button>',
        nextArrow: '<button type="button" class="slick-main__arrows slick-main__arrows_next"><i class="icons icon-arrow-circle-right"></i></button>',
    });

    initSlickCarousel.init(sliderNav, {
        dots: false,
        arrows: false,
        slidesToShow: 7,
        infinite: false,
        draggable: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                }
            }
        ]
    });

    sliderFor
        .on('beforeChange', function(event, slick, currentSlide, nextSlide){

            let smallSlides = sliderNav.find('.slick-slide');

            currentSlideNum.text(nextSlide + 1);

            if ( currentSlide < nextSlide ) {

                if ( !smallSlides.eq(nextSlide + 1).hasClass('slick-active') ) {
                    sliderNav.slick("slickNext");
                }

                smallSlides.removeClass('slick-current');
                smallSlides.eq(nextSlide).addClass('slick-current');

            } else {

                if ( !smallSlides.eq(nextSlide - 1).hasClass('slick-active') ) {
                    sliderNav.slick("slickPrev");
                }

                smallSlides.removeClass('slick-current');
                smallSlides.eq(nextSlide).addClass('slick-current');

            }

        })
        .on('afterChange', function(event, slick, currentSlide, nextSlide){

            let currentSlideEl = slick.$slides.eq(slick.currentSlide);

            downloadLink.attr("href", currentSlideEl.data("linkDownload"));
            imgSize.text(currentSlideEl.data("size"));

        });

    $('.js-update-big-slider').on('click', function() {

        let currentSlickIndex = $(this).data("slickIndex");

        sliderFor.slick('slickGoTo', parseInt(currentSlickIndex), false);

    });

    btnPrevSlide.on('click', function() {
        sliderFor.slick("slickPrev");
    });

    btnNextSlide.on('click', function() {
        sliderFor.slick("slickNext");
    });

};

export default initGallerySlider;