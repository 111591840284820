/**
 * Created by nitrox on 2/6/19.
 */

let errorHighlighter = (items, form) => {
    let no_field_errors = [];
    $.each(items, function (key, value) {
        let keys = key.split('.');
        let field_key = keys.shift();
        if(keys.length > 0){
            keys.forEach(function(item, i, arr) {
                field_key += '[' + item + ']';
            });
        }

        if(typeof form != 'undefined' && form.find(".form-group [name='"+ field_key +"']").length) {
            form.find(".form-group [name='" + field_key + "']").parents('.form-group').addClass('has-error');
            form.find(".form-group [name='" + field_key + "']").parents('.form-group').append('<div class="error">' + value + '</div>');
        }else{
            no_field_errors.push(value);
        }
    });
    if(no_field_errors.length){
        showNoFieldErrors(no_field_errors);
    }
};

let showMessage = (message) => {
    // alert(message);
};

let showNoFieldErrors = (message) => {
    alert(message);
};

let ajaxSubmit = (form) => {
    form.find(".form-group").removeClass('has-error');
    form.find(".form-group .error").remove();
    let formData = new FormData(form[0]);

    $.ajax({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        url: form.attr('action'),
        type: form.attr('method'),
        data : formData,
        cache: false,
        processData: false,
        contentType: false,
        beforeSend: function(jqXHR, settings){
            form.trigger('send', jqXHR, settings);
        },
        error: function(data){
            if(typeof data.responseJSON.errors != 'undefined'){
                errorHighlighter(data.responseJSON.errors, form);
            }

            grecaptcha.reset();

            form.trigger('error', data);
        },
        success: function (data) {
            if (typeof data.success != 'undefined' && data.success) {
                showMessage(data.message);
                form.get(0).reset();
            } else if(typeof data.error != 'undefined'){
                errorHighlighter(data.error, form);
            }

            form.trigger('success', data);
        }
    });
    return false;
};



module.exports = {
    errorHighlighter,
    showMessage,
    ajaxSubmit
};