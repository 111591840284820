import handlers from  './form/_form-handlers';

let subscribe = function(){
    let form = $('#subscription_modal form');
    form.on('success', function(e, data){
        $('#subscription_modal').find('.subscription-result').removeClass('hidden');
        form.addClass('hidden');
    });
    $('.subscription input, .subscription button').click(function(e){
        e.preventDefault();

        $('#subscription_modal').modal('show');

        form.find('button[type="submit"]').click(function(e){
            e.preventDefault();

            handlers.ajaxSubmit(form);
        });
    });

    if($('#subscription_resuly_modal').length){
        $('#subscription_resuly_modal').modal('show');
    }
};

$(document).ready(function(){
    subscribe();
});