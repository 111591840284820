let VatCheckFields = function() {
 $('#vat_form').on("submit", function() {
  if ($('#vat_kod_pdv').val().length == 0 && $('#vat_tin').val().length == 0 && $('#vat_name').val().length == 0 && $('#vat_n_reg').val().length == 0 && $('#vat_d_get').val().length == 0) {
  $('#vat_error').css('display', 'block');
  return false;
  }
$('#vat_error').css('display', 'none');
});
};
export default VatCheckFields;
