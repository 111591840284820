
import '../../../vendor/chosen-js/chosen.min.css';
import '../../../vendor/chosen-js/chosen.jquery';

import {destroyCustomScrollBar, initCustomScrollBarChosen} from "../_scrollbar-custom";

let initSelectChosen = function(el) {

	if ( !$(el).parent().hasClass('select-chosen-wrap') ) {
		$(el).wrap('<div class="select-chosen-wrap"></div>');
	}

	$(el).on('chosen:ready', function(evt, params) {
        console.log(params.chosen.search_field);
        params.chosen.search_field.attr('area-label', $('label[for="' + $(el).attr('id') + '"').text());
        params.chosen.search_field.attr('aria-labelledby', $(el).attr('id'));
        // console.log($('label[for="' + $(el).attr('id') + '"').text());
    }).chosen({
		disable_search_threshold: 1000,
		width: "100%",
		disable_search: true,
		browser_is_supported: function(){
			return true;
		}
	}).on('chosen:showing_dropdown', function(evt, params) {

		initCustomScrollBarChosen( params.chosen.search_results );

	}).on('chosen:hiding_dropdown', function(evt, params) {

		destroyCustomScrollBar( params.chosen.search_results );

	});

};

export default initSelectChosen;